<template>
  <article class="teaser campaign-teaser">
    <router-link :to="{name: 'Campaign', params: {id: camp.id}}">
    <!-- <router-link :to="'/campaign/' + camp.id"> -->
      <h4>
        {{camp.description}}
        <v-icon name="fa-chevron-right" />
      </h4>
      <time>
        <!-- {{timeFormatter(camp.start)}}—{{timeFormatter(camp.end)}} -->
        {{camp.bp_name}}
      </time>
    </router-link>
  </article>
</template>

<script>
import moment from 'moment'
moment.locale('it')

export default {
  props: ['camp'],
  methods: {
    timeFormatter (time) {
      return moment(time).format('ll')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/teaser-campaign.scss";
</style>
